import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";
import Tas from "../assets/images/Contact/Tas.png";
import Telfon from "../assets/images/Contact/Telfon.png";
import WA from "../assets/images/Contact/WA.png";

// Importing Section
const Navbar = React.lazy(() => import("../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../component/Footer/Footer"));

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "/", navheading: "Beranda", active: false, box: false },
        {
          id: 2,
          idnm: "fitur",
          navheading: "Fitur",
          active: false,
          box: false,
        },
        // {
        //   id: 3,
        //   idnm: "artikel",
        //   navheading: "Artikel",
        //   active: false,
        //   box: false,
        // },
        {
          id: 4,
          idnm: "kontak",
          navheading: "Kontak",
          active: true,
          box: false,
        },
        {
          id: 5,
          idnm: "register",
          navheading: "Register",
          active: false,
          box: true,
        },
        // { id: 1, idnm: "home", navheading: "Home" },
        // { id: 2, idnm: "about", navheading: "About Us" },
        // { id: 3, idnm: "services", navheading: "Services" },
        // { id: 4, idnm: "features", navheading: "Features" },
        // { id: 5, idnm: "project", navheading: "Project" },
        // { id: 6, idnm: "clients", navheading: "Clients" },
        // { id: 7, idnm: "contact", navheading: "Contact Us" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          <Container id="contact">
            <h2>KONTAK</h2>
            <div className="color-border big">
              <div className="contact-contains">
                <div className="contact-item">
                  <div className="contact-title">
                    <img src={Tas} alt="" />
                    <h3>Office</h3>
                  </div>
                  <p>
                    Ruko Pasar Alam, Jalan Alun-Alun Selatan No. 46,
                    RT.004/RW.016. Kel. Padurenan Kec. Mustika Jaya, Bumi Pala,
                    Vida, Bekasi City, West Java 16340
                  </p>
                </div>
                <div
                style={{
                  height: window.innerWidth < 768 ? "100px" : "140px", // Adjust height for smaller screens
                  width: "3px",
                  borderRadius: "4px",
                  backgroundColor: "#FC663E",
                  display: window.innerWidth < 768 ? "none" : "block", // Hide divider on smaller screens if needed
                }}
              ></div>
                {/* <div className="contact-item">
                  <div className="contact-title">
                    <img src={Tas} alt="" />
                    <h3>Office</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur Nunc vulputate
                    libero et velit interdum, ac aliquet odio mattis.
                  </p>
                </div> */}
                <div className="contact-item">
                  <div className="contact-title">
                    <img src={Telfon} alt="" />
                    <h3>Contact</h3>
                  </div>
                  <p>
                    +62 852-1700-4359 <br />{" "}
                    <a
                      href="mailto:official@desapedia.co.id"
                      style={{
                        color: "#202020",
                      }}
                    >
                      official@desapedia.co.id
                    </a>
                  </p>
                </div>
                <button
                  className="wa color-border"
                  style={{
                    border: 0,
                  }}
                  onClick={() => window.open("https://wa.link/07yywu/")}
                >
                  <div className="wa-contains">
                    <img src={WA} alt="" />
                    <h3>Hubungi Kami</h3>
                  </div>
                </button>
              </div>
            </div>
          </Container>

          {/* Importing Footer */}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default Contact;
